import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Button, Card, CardBody, Input } from 'reactstrap';
import { withTracking } from 'react-tracking';
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { convertFromHTML, convertToHTML } from 'draft-convert';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Link } from "react-router-dom";
import ContactDetailsDropdown from "../ContactDetailsDropdown";
import linkifyHtml from 'linkifyjs/html';
import SelectSearch from 'react-select-search';
import { defaultTemplates } from '../../pages/ProductAdmin/LeadReplyTemplates';
import useAuth from "../../pages/Authentication/useAuth";
import { Checkbox, Chip, FormControlLabel, FormGroup, Grid, Stack, TextField, Typography, Button as MuiButton, Alert, AlertTitle, MenuItem } from '@mui/material';
import isTestAccount from 'utils/products/isTestAccount';
import DateTimeTooltip from '@ei/components/date-time-tooltip';
import LeadSnippet from './LeadSnippet';
import RegionsTooltip from "./RegionsTooltip";
import * as _ from 'lodash'
import { WHEN_OPTIONS } from 'pages/ProductAdmin/Leads/maps';

export const capitalize = string => {
  if (!string) return '';
  return string.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ').trim();
}

/**
 * @param {{ leadStatuses: { id: string, title: string, use: bool }[] }}} params 
 */
const ProductLead = ({ tracking, lead, hideDividers, style, product, replyToLead, deleteLead, rejectLead, approveLead, leadStatuses, setLeadStatus, metadata }) => {

  const [replyOpen, setReplyOpen] = useState(true);
  const [newReply, setNewReply] = useState('');
  const [message, setMessage] = useState('');
  const [name, setName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [source, setSource] = useState('');
  const [manualOrg, setManualOrg] = useState('');
  const [org, setOrg] = useState('');
  const [isOrgNameManualEntry, setIsOrgNameManualEntry] = useState(false)
  const [leadValue, setLeadValue] = useState(0);
  const [numberOfCredits, setNumberOfCredits] = useState(0);
  const [status, setStatus] = useState(null);
  const [selectedAlternatives, setSelectedAlternatives] = useState([]);

  useEffect(() => {
    setStatus(null);
    setName(lead.user?.name || capitalize(lead.name))
    setMessage(lead.message)
    setJobTitle(lead.user?.job_title || capitalize(lead.jobTitle))

    if (lead.userData?.school?.school?.manualEntry) setIsOrgNameManualEntry(true)

    const manualOrgName = lead?.userData?.school?.school?.name
    const manualOrgLocation = lead?.userData?.school?.school?.formatted_address

    if (isOrgNameManualEntry) setManualOrg(`${manualOrgName}, ${manualOrgLocation}`)

    // On the API, we set org as the org name. If present, this should be considered the most authoritative source.
    if (_.isString(lead.org)) {
      setOrg(capitalize(lead.org))
    }
    // Fallback to EI database.
    else if (lead.orgData?.name) {
      setOrg(capitalize(lead.org.name))
    }
    // Fallback to Google database.
    else if (lead.orgData?.description) {
      setOrg(capitalize(lead.orgData?.description))
    }
    // Fallback to user data.
    else {
      setOrg(lead.user?.org_name)
    }

    setSource(lead.source || 'Profile')
  }, [lead]);


  const handleAlternativeToggle = supabaseId => {
    if (selectedAlternatives.includes(supabaseId)) {
      setSelectedAlternatives(selectedAlternatives.filter(id => id !== supabaseId))
    } else {
      setSelectedAlternatives([...selectedAlternatives, supabaseId])
    }
  }

  const isInternalAdmin = !!approveLead

  const templates = {
    ...defaultTemplates,
    ...(product ? product.templates : {}),
  };

  const { reply } = lead;
  const id = lead.TYPE;
  const [firstname, lastname] = name.split(' ');

  // ? Why are we relying on...the org name as the instituition type?
  // console.log('lead.institution', lead.institution)
  const institution = lead.institution ? capitalize(lead.institution) : lead.user?.org_name;

  const country = lead.country ? lead.country : '';
  const replyToEmail = product?.salesNotificationEmails ? product?.salesNotificationEmails[0] : product?.loginEmails[0];
  const { currentUser, supabase } = useAuth();

  /**
   * @param {LeadSource} source 
   */
  const getLabelForSource = (source) => {
    switch (source) {
      case 'Competitor':
        return 'Other'
      case 'Email':
        return 'Email'
      case 'Event':
        return 'Event'
      case 'Profile':
      default:
        return 'Direct'
    }
  }

  const selectStatus = status => {
    setStatus(status);
    setLeadStatus({ lead, status });
  }

  const institutionTitle = institution => {
    if (institution.includes('Education') || institution.includes('Independent')) {
      return `${institution} Institution`;
    }
    return institution;
  }

  const replacePlaceholders = content => (
    content
      .replace(/{{firstname}}/g, firstname)
      .replace(/{{lastname}}/g, lastname || '')
      .replace(/{{productName}}/g, product.name)
      .replace(/{{organisationName}}/g, org)
      .replace(/{{institutionType}}/g, institutionTitle(institution))
      .replace(/{{email}}/g, currentUser.email)
  );

  const selectTemplate = templateId => {
    let content = '';
    if (templateId) {
      content = replacePlaceholders(templates[templateId].content);
    };
    content = EditorState.createWithContent(convertFromHTML(content));
    setNewReply(content);
  }

  const submitReply = id => () => {
    if (!newReply) return;
    let newReplyPayload = convertToHTML(newReply.getCurrentContent());
    if (newReplyPayload === '<p></p>') { newReplyPayload = '' };
    newReplyPayload = linkifyHtml(newReplyPayload, { defaultProtocol: 'https' });
    replyToLead({ id, reply: newReplyPayload });
    setReplyOpen(false);
    tracking.trackEvent({ event: 'replied-to-lead' });
  }

  const Date = ({ date, className = '' }) => {
    date = moment(date, 'YYYY-MM-DDThh:mm');
    return <StyledDate className={className}>{date.isValid() ? date.format('D MMM YYYY h:mma') : ''}</StyledDate>;
  }

  const isDownloadLead = lead?.type === "download"
  const isEvidenceDownloadLead = isDownloadLead && lead?.origin && lead?.origin === "evidence" && !!lead.downloads
  const isResourceDownloadLead = isDownloadLead && lead?.origin && lead?.origin === "url" && !!lead.downloads

  return (
    <Main hideDividers={hideDividers} style={style}>
      <Card style={isTestAccount(product?.supabaseId) ? { background: '#F8F1F1' } : {}}>
        <CardBody>
          <LeadHeader>
            {isInternalAdmin &&
              <>
                {(lead.isRefunded || lead.noSupplierEmail || lead?.notes || lead.isPossibleDuplicate)
                  ? (
                    <Alert severity='warning'>
                      <AlertTitle sx={{ textTransform: "capitalize" }}>Important information</AlertTitle>
                      {/* Alert content is already padded, let's reduce default padding. */}
                      <ol style={{ paddingInlineStart: "2ch" }}>
                        {lead.isRefunded
                          ? (
                            <li>This is a <strong>refunded</strong> lead</li>
                          )
                          : null
                        }
                        {lead.noSupplierEmail &&
                          <li>
                            <strong>No supplier email</strong> on file
                          </li>
                        }
                        {lead?.notes &&
                          <li>
                            <strong>Notes:</strong>&nbsp;{lead.notes}
                          </li>
                        }
                        {lead.isPossibleDuplicate &&
                          <li>
                            <details>
                              <summary>This might be a duplicate lead! Consider the following leads:</summary>
                              {lead.possibleDuplicates.map(l => {
                                return <LeadSnippet key={l.ddb_type} lead={l} />
                              })}
                            </details>
                          </li>
                        }
                      </ol>
                    </Alert>
                  )
                  : null}

                <Name className="mb-2">
                  <strong>Product: </strong><Link to={`/products/${lead.slug}/profile`}>{lead.slug}</Link> <RegionsTooltip regions={lead.product.regions} />
                </Name>

                {lead.alternativeTo &&
                  <Name className="mb-2">
                    <strong>Alternative to: </strong>{lead.alternativeTo}
                  </Name>
                }
              </>
            }
            <HeaderTop className="mb-2">
              <DateTimeTooltip value={lead.date} typographySx={{ color: "inherit" }} />
              <div className="d-flex align-items-center">
                <SelectSearch
                  placeholder="Move to..."
                  options={leadStatuses
                    .filter(status => status.id !== lead.supplierStatus)
                    .filter(status => status.use)
                    .map(status => ({ name: status.title, value: status.id }))
                  }
                  onChange={selectStatus}
                  value={status}
                />
                {(reply || isInternalAdmin) && <ContactDetailsDropdown className="ml-2" email={lead.email} phone={lead.phone} />}
              </div>
            </HeaderTop>
            <HeaderTop className={reply ? "mb-3" : "mb-2"}>
              <Name>
                <strong>{capitalize(lead.type)} request: </strong>{org}
              </Name>
            </HeaderTop>

            {lead?.educatorScheduleIntent
              ? (
                <Typography><Typography variant='body1' component='strong'>Educator schedule intent:</Typography>&nbsp;{WHEN_OPTIONS[lead.educatorScheduleIntent]}</Typography>
              )
              : null}

            {(isEvidenceDownloadLead || isResourceDownloadLead) && (
              <>
                <h5>{isEvidenceDownloadLead ? 'Evidence' : 'Resources'} downloaded:</h5>
                <ul>
                  {lead?.downloads && Array.isArray(lead?.downloads) && lead?.downloads.length
                    ? (lead.downloads.map((download) => {
                      if (download?.downloadType === "resource") {
                        return (
                          <li key={download.createdAt}>
                            <Typography mb={1}>{download.title}</Typography>
                          </li>
                        )
                      }

                      return (
                        <li key={download.createdAt}>
                          <Typography mb={1}>{download.schoolName}</Typography>
                          {download.location && <Typography mb={1}>{download.location === 'United Kingdom' ? ', UK' : download.location ? `, ${download.location}` : ''}</Typography>}
                        </li>
                      )
                    }))
                    : null}
                </ul>
              </>
            )}

            {message && isInternalAdmin &&
              <Input
                style={{ height: '80px', maxWidth: '600px' }}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                type="textarea"
              />
            }

            {message && !approveLead &&
              <JobTitle>{message}</JobTitle>
            }

            <hr />

            <Stack spacing={0}>
              <JobTitle>{isOrgNameManualEntry ? manualOrg : org}<br />{institution}<br />{country}</JobTitle>

              {lead.howMany && <JobTitle>{lead.howMany} students on roll</JobTitle>}

              <hr />

              <Stack direction="row" spacing={3}>
                <TextField
                  sx={{ width: '18rem' }}
                  label="Organisation"
                  value={org}
                  onChange={e => setOrg(e.target.value)}
                  name="org"
                />

                <TextField
                  sx={{ width: '18rem' }}
                  label="Name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  name="name"
                />

                <TextField
                  sx={{ width: '18rem' }}
                  label="Job Title"
                  value={jobTitle}
                  onChange={e => setJobTitle(e.target.value)}
                  name="jobTitle"
                />

                <TextField
                  select
                  sx={{ width: '18rem' }}
                  label="Source"
                  value={source}
                  onChange={e => setSource(e.target.value)}
                  name="source"
                >
                  {['Profile', 'Event', 'Email', 'Competitor'].map(option => (
                    <MenuItem key={option} value={option}>
                      {option}{getLabelForSource(option) !== option ? ` (labelled as ${getLabelForSource(option)})` : ''}
                    </MenuItem>
                  ))}
                </TextField>

              </Stack>
            </Stack>

            {lead.user?.subjects &&
              <JobTitle>User subjects: {lead.user?.subjects.map(s => s.title).join(', ')}</JobTitle>
            }
          </LeadHeader>

          <React.Fragment>

            {reply &&
              <>
                <Reply className="mt-3">
                  {reply.request && <Date date={reply.request} />}
                  <strong>Your reply:</strong>
                  <div dangerouslySetInnerHTML={{ __html: reply.message || reply }} />
                </Reply>
              </>
            }

            <>
              {replyOpen && !reply && !!replyToLead &&
                <div className="mt-3">
                  <JobTitle>
                    <strong>{name} is awaiting your reply:</strong>
                    <div>Include your name and a direct link to book in a time to speak with you.</div>
                    <div style={{ color: 'red' }}>Important: The email address of the educator will be shown to you after submitting your reply.</div>
                  </JobTitle>
                  <div className="d-flex align-items-center my-2">
                    <SelectSearch
                      options={[
                        { name: 'Select Template', value: '' },
                        ...Object.keys(templates).map(templateId => ({
                          name: templates[templateId].name,
                          value: templateId,
                        }))
                      ]}
                      onChange={selectTemplate}
                    />
                    <Link className="ml-3 font-weight-normal" to={`/products/${product.slug}/leads/templates`}>Edit templates</Link>
                  </div>
                  <Editor
                    editorState={newReply}
                    onEditorStateChange={newReply => setNewReply(newReply)}
                    toolbar={{
                      options: ['inline', 'history'],
                      inline: {
                        options: ['bold', 'italic', 'underline'],
                      },
                    }}
                  />
                  <div style={{ color: '#aaa' }}>
                    Your reply will be sent from EdTech Impact. The reply-to will go to {replyToEmail}. <Link to={`/products/${product.slug}/admin/notifications`}>Edit</Link>
                  </div>
                </div>
              }

              {replyOpen && !reply && !!replyToLead &&
                <div className="mt-3">
                  <Button disabled={!newReply} color="primary" onClick={submitReply(id)}>
                    Send reply
                  </Button>
                </div>
              }

              {approveLead && <div className="mt-2">Monetary balance: £{lead.monetaryBalance}</div>}
              {approveLead && <div className="mt-2">Lead credits balance: {lead.freeLeadCount > 0 ? `${lead.freeLeadCount} free ${lead.freeLeadCount === 1 ? "lead" : "leads"}` : `${lead.paidLeadCount} paid ${lead.paidLeadCount === 1 ? "lead" : "leads"}`}</div>}

              <Stack spacing={6} mt={4}>
                {!lead.alternativeTo && approveLead &&
                  <FormGroup>
                    {lead.customAlternatives &&
                      <Typography variant='h4' mb={4}>Requested custom alternatives&nbsp;✅</Typography>
                    }
                    <Typography variant='h6'>Share lead with:</Typography>
                    <Grid container>
                      {lead.product?.alternatives?.map(alt => {
                        // Not sure how a null key has made it in. Temporary patch until I look at it tomorrow.
                        if (!alt) return null
                        const _isPremium = alt.plan && alt.plan !== 'free'

                        return (
                          <Grid item xs="12" sm="6" lg="4" xxl="3">
                            <FormControlLabel
                              key={alt.supabaseId}
                              control={
                                <Checkbox
                                  checked={selectedAlternatives.includes(alt.supabaseId)}
                                  onChange={() => handleAlternativeToggle(alt.supabaseId)}
                                  inputProps={{ "aria-label": "controlled", }}
                                  sx={{ padding: "5px 9px", }}
                                />
                              }
                              label={<>{alt.name} {_isPremium && <Chip color="success" size="small" label="Pro" />}<RegionsTooltip regions={alt.regions} /></>}
                              sx={{ marginBottom: 0, }}
                            />
                          </Grid>
                        )
                      }
                      )}
                    </Grid>
                  </FormGroup>
                }

                <Stack direction={{ xs: "column", lg: "row" }} gap={3} alignItems="center">

                  {approveLead &&
                    <TextField
                      label="Number of credits"
                      value={numberOfCredits}
                      onChange={e => setNumberOfCredits(e.target.value < 0 ? 0 : Number(e.target.value))}
                      type="number"
                      name="numberOfCredits"
                      required
                      InputProps={{ sx: { mb: 0 } }}
                    />
                  }

                  {approveLead &&
                    <Stack direction={{ xs: "column", lg: "row" }} gap={1}>
                      <MuiButton
                        variant="contained"
                        color="primary"
                        onClick={() => approveLead(
                          {
                            ...lead,
                            name,
                            jobTitle,
                            org,
                            source,
                          },
                          message,
                          leadValue,
                          numberOfCredits,
                          selectedAlternatives,
                        )}
                      >
                        Approve
                      </MuiButton>
                    </Stack>
                  }

                  {approveLead && lead.alternativeTo &&
                    <MuiButton
                      variant="outlined"
                      color="primary"
                      onClick={() => approveLead(
                        {
                          ...lead,
                          name,
                          jobTitle,
                          org,
                          source,
                        },
                        null,
                        leadValue,
                        numberOfCredits,
                        selectedAlternatives,
                      )}>
                      Approve without Message
                    </MuiButton>
                  }

                  {rejectLead &&
                    <MuiButton
                      variant="contained"
                      color="error"
                      onClick={() => rejectLead(lead)}>
                      Reject
                    </MuiButton>
                  }

                  {deleteLead &&
                    <MuiButton
                      variant="contained"
                      color="secondary"
                      onClick={() => deleteLead(lead)}>
                      Delete
                    </MuiButton>
                  }
                </Stack>
              </Stack>

            </>
          </React.Fragment>
        </CardBody>
      </Card>
    </Main>
  );
}

const Reply = styled(Card)`
  border-left: 5px solid #02a499;
  font-size: 0.875rem;
  padding: 0.875rem;
  margin-bottom: 0;

  p:last-child {
    margin-bottom: 0;
  }
`;

const ReplyStatus = styled.div`
  margin-top: 1rem;
  text-transform: capitalize;

  span {
    display: inline-flex;
    align-items: center;
    margin-right: 1.5rem;
  }

  i {
    color: #02a499;
    margin-right: 0.5rem;
  }
`;


const Main = styled.div`
  ${({ hideDividers }) =>
    !hideDividers &&
    `
    border-bottom: 1px solid rgba(0,0,0,.1);
    margin-bottom: 3rem;
  `};

  .rdw-editor-wrapper {
    border-left: 5px solid #0482A2;
    border-radius: 0.25rem;
    margin: 0.25rem 0;
  }
`;

const LeadHeader = styled.div`
  hr {
    border: 2px dotted rgba(0,0,0,.1);
  }
`;

const HeaderTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .select-search__input {
    width: 8.5rem;
    height: 34px;
  }
`;

const Name = styled.h4`
  margin-bottom: 0;
  font-weight: normal;
`;

const JobTitle = styled.div`
  margin-bottom: 0;
`;

const StyledDate = styled.h6`
  display: inline-block;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
  color: #aaa;
  font-weight: normal;
`;

export default withTracking()(ProductLead);
